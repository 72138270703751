import { useState } from 'react';

import { InvoicingWorkflow } from '../../amplitude';
import { DetailedTreatmentPlan } from '../../API/treatmentPlans';
import { Appointment } from '../../ServiceContext/appointments';
import Button from '../../shared/Button/Button';
import { toShortDateString } from '../../shared/dates/dates';
import { stringifyMoney } from '../../shared/money/stringifyMoney';

type Props = {
  patientTreatmentPlanData: DetailedTreatmentPlan[];
  appointment: Appointment;
  onCancelClick: () => void;
  onImportCDTCodesClick: (selectedPlan: DetailedTreatmentPlan) => void;
};
const ImportFromTPModalContent: React.FC<Props> = ({
  patientTreatmentPlanData,
  appointment,
  onCancelClick,
  onImportCDTCodesClick,
}) => {
  const [selectedPlanId, setSelectedPlanId] = useState<string | null>(null);

  const handleImportCDTCodesClick = (planId: string | null) => {
    const selectedPlan = patientTreatmentPlanData.find((plan) => plan.id === planId);
    if (selectedPlan) {
      onImportCDTCodesClick(selectedPlan);
    }
  };
  const handleRadioChange = (planId: string) => {
    setSelectedPlanId(planId);
  };
  const buttonStyling =
    'bg-primary text-white font-semibold cursor-pointer hover:opacity-75 disabled:opacity-50 disabled:cursor-default';

  return (
    <div className={'flex flex-col gap-3 text-neutral'}>
      <span id={'title'} className={'text-xl font-bold'}>
        Import from Treatment Plan
      </span>
      <span className={'text-xs'}>Select a Treatment Plan to import CDT Codes from: </span>
      <form className={'flex flex-col gap-3'}>
        {patientTreatmentPlanData.map((plan) => {
          const timeZone = appointment.timeZone;
          const totalValue = plan.items
            .map((item) => item.fullFeeParsed || 0)
            .reduce((accumulator, currentValue) => accumulator + currentValue, 0);
          const cdtCodes = plan.items.map((item) => item.cdtCode);
          return (
            <div
              id={'treatment-plan-option'}
              key={plan.id}
              className={`flex flex-col gap-2 p-3 border rounded-md ${
                selectedPlanId === plan.id ? 'bg-blue-50' : 'bg-base-300/75'
              }`}
            >
              <div className={'flex flex-row gap-1'}>
                <input
                  title="Select Treatment Plan to import CDT Codes from"
                  type={'radio'}
                  value={plan.id}
                  checked={selectedPlanId === plan.id}
                  onChange={() => handleRadioChange(plan.id)}
                  className={'mr-2'}
                />
                <span className={'text-xs font-bold'}>{`${toShortDateString(
                  plan.createdAt,
                  timeZone
                )} (Total Value: ${stringifyMoney(totalValue, { includeCommas: true })})`}</span>
              </div>
              <span className={'text-xs ml-6'}>
                <span className={'font-bold'}>CDT Codes: </span>
                {cdtCodes.join(', ')}
              </span>
            </div>
          );
        })}
        <div className={'flex flex-row gap-2 mb-3 justify-center w-full mt-3'}>
          <Button
            onClick={() => handleImportCDTCodesClick(selectedPlanId)}
            className={buttonStyling}
            workflow={InvoicingWorkflow}
            trackingLabel="Import CDT Codes to Invoice Button"
            context="importFromTPModalContent"
          >
            Import CDT Codes to Invoice
          </Button>
          <Button
            onClick={onCancelClick}
            className={'bg-white border border-rule text-primary shadow-none hover:opacity-75'}
            workflow={InvoicingWorkflow}
            trackingLabel="Cancel Import CDT Codes to Invoice Button"
            context="importFromTPModalContent"
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ImportFromTPModalContent;
