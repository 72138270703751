import { PaymentMethod } from './invoices';
import { Address, Service } from './shared';

export interface UserBase {
  id: string;
  username: string;
  firstName: string;
  lastName: string;
  email: string;
  license: string;
  photoUrl: string;
  birthDate: string;
  status: string;
  paymentMethods: PaymentMethod[];
  tags: Tag[];
  rating: number;
  locations: Address[];
  about: string;
  educations: Education[];
  languages: Language[];
  gender: string;
  services: Service[];
  ratings: Rating[];
  reviews: Review[];
  hours: DayHours[];
  fcmTokens?: string[];
  allowsPush?: boolean;
  deletedEmail: string | null;
  isFlossyAdmin: boolean;
}

export type Manager = UserBase & {
  type: 'Manager';
  practices: Practice[];
  managerType: 'office_manager' | 'group_manager' | 'flossy_admin';
};

export type Dentist = UserBase & {
  type: 'Dentist';
  practice: Practice;
  googleId: string;
  stripeCustomerId: string;
  email: string;
  phone: string;
  feeSchedule: string;
  usesNewPricingSystem: boolean;
  specialty: string;
  disabled: boolean;
};

export function isManager<T>(s: T | UserBase): s is Manager {
  return (
    s !== undefined &&
    s !== null &&
    (s as Manager).type !== undefined &&
    (s as Manager).type !== null &&
    (s as Manager).type === 'Manager'
  );
}

export function isOfficeManager<T>(s: T | UserBase): s is Manager {
  return isManager(s) && s.managerType === 'office_manager';
}

export function isRegionalManager<T>(s: T | UserBase): s is Manager {
  return isManager(s) && s.managerType === 'group_manager';
}

export function isDentist<T>(s: T | UserBase): s is Dentist {
  return (
    s !== undefined &&
    s !== null &&
    (s as Dentist).type !== undefined &&
    (s as Dentist).type !== null &&
    (s as Dentist).type === 'Dentist'
  );
}

export interface Practice {
  id: string;
  name: string;
  requiresLatestTermsOfServiceAgreement?: boolean;
  dentists: Dentist[];
  referralCreditAmount: number | null;
  timeZone?: string;
  usesNewDentistPortal?: boolean;
  paymentMethods: PaymentMethod[];
  defaultPaymentMethod?: PaymentMethod;
}

export interface Tag {
  id: string;
  name: string;
}

export interface MediaResource {
  url: string;
  type: string;
  name: string;
  timestamp: string;
}

export interface Education {
  text: string;
}

export interface Language {
  id: string;
  name: string;
}

export interface Rating {
  source: RatingSourceType;
  rating: number;
}

export interface RatingSourceType {
  name: string;
}

export interface Review {
  id: string;
  review: string;
  name: string;
  photoUrl: string;
  date: string;
}

export interface DayHours {
  day: Day;
  hours: HoursRange;
}

type Day = 'Monday' | 'Tuesday' | 'Wednesday' | 'Thursday' | 'Friday' | 'Saturday' | 'Sunday';

export interface HoursRange {
  start: number;
  end: number;
}

export function getDentistAddress(dentist: UserBase) {
  if (dentist.locations.length === 0) {
    return {} as Address;
  }

  return dentist.locations[0];
}
