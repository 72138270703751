import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useWizard, WizardValues } from 'react-use-wizard';
import { v4 as uuidv4 } from 'uuid';

import { InvoicingWorkflow, Tracking } from '../../amplitude';
import { ConsolidatedPricingTable } from '../../API/dentist';
import { createDraftInvoice, updateDraftInvoice } from '../../API/invoices';
import { ErrorResponse } from '../../API/response';
import {
  DetailedTreatmentPlan,
  getDetailedTreatmentPlans,
  GetDetailedTreatmentPlansResponse,
} from '../../API/treatmentPlans';
import { AuthProvider } from '../../Authentication/Authentication';
import { Appointment } from '../../ServiceContext/appointments';
import { Invoice, TransactionPaymentTypeInsurance } from '../../ServiceContext/invoices';
import { Patient } from '../../ServiceContext/patients';
import { Dentist } from '../../ServiceContext/user';
import Alert, { AlertData, errorAlert } from '../../shared/Alert';
import Button from '../../shared/Button/Button';
import LoadingSpinner from '../../shared/LoadingSpinner';
import Modal from '../../shared/Modal/Modal';
import AppointmentSummary from '../AppointmentSummary';
import useCDTCodeEntry from '../CDTs/useCDTCodeEntry';
import ImportFromTPModalContent from './ImportFromTPModalContent';

type Props = {
  authProvider: AuthProvider;
  appointment: Appointment;
  invoice: Invoice | null;
  patient: Patient;
  dentist: Dentist;
  consolidatedPricingTable: ConsolidatedPricingTable;

  processorName: string;
  onProcessorNameChange: (processorName: string) => void;
  onDraftInvoiceCreatedOrUpdated: (invoice: Invoice) => void;

  onCancel: () => void;
  goToReviewInvoice: (w: WizardValues) => void;
};

const CreateOrEditInvoiceStep: React.FC<Props> = ({
  authProvider,
  appointment,
  invoice,
  patient,
  dentist,
  consolidatedPricingTable,
  processorName,
  onProcessorNameChange,
  onDraftInvoiceCreatedOrUpdated,
  onCancel,
  goToReviewInvoice,
}) => {
  const tracking: Tracking = {
    workflow: InvoicingWorkflow,
    context: 'createInvoice',
    properties: {
      appointmentId: appointment.id,
      patientId: patient.id,
      dentistId: dentist.id,
      invoicingType: 'standard',
    },
  };

  const w = useWizard();

  const [alert, setAlert] = useState<AlertData | null>(null);
  const [isImportModalOpen, setIsImportModalOpen] = useState(false);

  const patientName = `${patient.firstName} ${patient.lastName}`;
  const dentistName = `${dentist.firstName} ${dentist.lastName}`;

  const location = useLocation();
  const treatmentPlan = (location.state?.treatmentPlan || null) as DetailedTreatmentPlan | null;

  useEffect(() => {
    if (treatmentPlan) {
      importCDTCodesFromTreatmentPlan(treatmentPlan.items);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { mutate: createDraftInvoiceMutation, isLoading: isCreatingDraftInvoice } = useMutation(
    createDraftInvoice,
    {
      onSuccess: async (data) => {
        data && onDraftInvoiceCreatedOrUpdated(data);
        goToReviewInvoice(w);
      },
      onError: (error) => {
        setAlert(errorAlert(error as any));
      },
    }
  );

  const { mutate: updateDraftInvoiceMutation, isLoading: isUpdatingDraftInvoice } = useMutation(
    updateDraftInvoice,
    {
      onSuccess: async (data) => {
        data && onDraftInvoiceCreatedOrUpdated(data);
        goToReviewInvoice(w);
      },
      onError: (error) => {
        setAlert(errorAlert(error as any));
      },
    }
  );

  const {
    input: CDTInputComponent,
    table: CDTTable,
    selectedCodes,
    isPerformingNetworkCalls,
    importCDTCodesFromTreatmentPlan,
  } = useCDTCodeEntry({
    authProvider,
    dentist,
    patient,
    invoice,
    promoCode: appointment.promoCode,
    onErrorChange: setAlert,
    onInvoiceUpdated: onDraftInvoiceCreatedOrUpdated,
    consolidatedPricingTable,
  });

  const buttonStyling =
    'bg-primary text-white font-semibold cursor-pointer hover:opacity-75 disabled:opacity-50 disabled:cursor-default';

  const onCancelClicked = () => {
    onCancel();
  };

  const onReviewClicked = async () => {
    const billingCodes = selectedCodes.map((p) => p.cdt);
    if (billingCodes.length === 0) {
      return;
    }

    if (
      selectedCodes.filter(
        (p) =>
          p.additionalNotes === null ||
          p.additionalNotes === undefined ||
          p.additionalNotes?.trim().length === 0
      ).length > 0
    ) {
      toast.error('Please fill out all additional notes fields');
      return;
    }

    const additionalNotes = selectedCodes.map((p) => p.additionalNotes || '');
    const pricingSystemIds = selectedCodes.map((p) => p.pricingSystemId || uuidv4());

    if (invoice) {
      updateDraftInvoiceMutation({
        authProvider,
        appointmentId: appointment.id,
        billingCodes,
        paymentType: appointment.patientInsuranceId ? TransactionPaymentTypeInsurance : undefined,
        additionalNotes,
        pricingSystemIds,
      });
    } else {
      createDraftInvoiceMutation({
        authProvider,
        appointmentId: appointment.id,
        billingCodes,
        paymentType: appointment.patientInsuranceId ? TransactionPaymentTypeInsurance : undefined,
        additionalNotes,
        pricingSystemIds,
      });
    }
  };

  const getTopControlButtons = () => {
    const isReadyForReview = selectedCodes.length > 0 && alert === null;
    let disableReview: string | boolean;

    disableReview =
      isPerformingNetworkCalls || !processorName || !isReadyForReview || selectedCodes.length === 0;

    return (
      <div className={'flex flex-row gap-2 justify-center w-full pb-14 pt-4'}>
        <Button
          id="review-invoice-button"
          key="review-invoice-button"
          onClick={onReviewClicked}
          className={buttonStyling}
          loading={isCreatingDraftInvoice || isUpdatingDraftInvoice}
          disabled={disableReview}
          trackingLabel="Review Invoice Button"
          {...tracking}
        >
          Review Invoice
        </Button>
        <Button
          id="cancel-button"
          key="cancel-button"
          onClick={onCancelClicked}
          className="border border-rule text-primary bg-white hover:opacity-75 shadow-none"
          disabled={isPerformingNetworkCalls || isCreatingDraftInvoice || isUpdatingDraftInvoice}
          trackingLabel="Cancel Invoice Creation"
          {...tracking}
        >
          Cancel
        </Button>
      </div>
    );
  };

  const patientTreatmentPlanQueryKey = ['getTreatmentPlansForPatient', patient.id, dentist.id];

  const {
    isLoading: isFetchingPatientTreatmentPlan,
    data: patientTreatmentPlanResponse,
    error: patientTreatmentPlanError,
  } = useQuery<GetDetailedTreatmentPlansResponse, ErrorResponse>(
    patientTreatmentPlanQueryKey,
    () => {
      let practiceId: string | undefined;
      if (!authProvider.isFlossyAdmin) {
        practiceId = authProvider.authUser?.user.practices[0]?.id || undefined;
      }
      return getDetailedTreatmentPlans({
        authProvider,
        patientId: patient.id,
        practiceId,
      });
    },
    {
      enabled: Boolean(patient.id),
    }
  );

  const handleImportCDTCodes = (selectedPlan: DetailedTreatmentPlan) => {
    importCDTCodesFromTreatmentPlan(selectedPlan.items);
    setIsImportModalOpen(false);
  };

  if (isFetchingPatientTreatmentPlan) {
    return <LoadingSpinner />;
  }

  if (patientTreatmentPlanError) {
    setAlert(errorAlert(patientTreatmentPlanError));
  }

  const pageTitle = invoice ? 'Edit Invoice' : 'Create Invoice';

  const noTreatmentPlans =
    patientTreatmentPlanResponse && patientTreatmentPlanResponse.totalCount === 0;

  return (
    <div
      id="create-invoice-step-content"
      className="h-full flex flex-col gap-3 text-secondary relative"
    >
      <span id={'title'} className={'font-semibold text-2xl text-left'}>
        {pageTitle}
      </span>
      <div id={'body'} className="bg-white rounded-md flex flex-col gap-2 items-start ">
        <AppointmentSummary
          appointment={appointment}
          dentistName={dentistName}
          patientName={patientName}
        />
        <span className={'text-xs pl-6'}>
          Please enter processor name and CDT codes for services rendered today:
        </span>
        <div className="processor-name-entry flex flex-row items-center my-3 w-1/3">
          <label
            className="processor-name-label text-left min-w-[144px] text-sm font-semibold pl-6"
            htmlFor="processor-name-input"
          >
            Processed By:
          </label>
          <input
            id="processor-name-input"
            value={processorName}
            className="border rounded-md p-2.5 text-xs focus:outline-blue-200 w-full"
            onChange={(e) => {
              onProcessorNameChange(e.target.value);
            }}
            placeholder="Enter processor's name"
          />
        </div>
        <div
          id={'cdt-select-and-import-treatment-plan-wrapper'}
          className={'flex flex-row gap-3 items-center w-full pl-4'}
        >
          <div className={'flex flex-col gap-2 w-1/2'}>{CDTInputComponent}</div>
          <span className={'font-semibold'}>or</span>
          <Button
            className={`text-nowrap ${noTreatmentPlans ? 'opacity-50 cursor-not-allowed' : ''}`}
            onClick={() => {
              if (noTreatmentPlans) {
                toast.error('No treatment plans on file this patient.');
              } else {
                setIsImportModalOpen(true);
              }
            }}
            trackingLabel="Import CDT Codes from Treatment Plan"
            {...tracking}
          >
            Import from Treatment Plan
          </Button>
        </div>
        {alert && (
          <div className="px-3 w-full">
            <Alert {...alert} />
          </div>
        )}
        {CDTTable}
        {selectedCodes.length === 0 && (
          <span id={'instructions'} className={'text-center text-xs opacity-50 w-full pt-12'}>
            Enter CDT codes above, or import codes from a treatment plan, to begin generating
            invoice items.
          </span>
        )}
        {getTopControlButtons()}
      </div>
      <Modal
        isOpen={isImportModalOpen}
        onRequestClose={() => setIsImportModalOpen(false)}
        shape={'square'}
      >
        {patientTreatmentPlanResponse && (
          <ImportFromTPModalContent
            patientTreatmentPlanData={patientTreatmentPlanResponse.treatmentPlans}
            appointment={appointment}
            onCancelClick={() => setIsImportModalOpen(false)}
            onImportCDTCodesClick={handleImportCDTCodes}
          />
        )}
      </Modal>
    </div>
  );
};

export default CreateOrEditInvoiceStep;
