import React, { useState } from 'react';

import { ShareInvoiceWorkflow } from '../../../../amplitude';
import {
  Booking,
  BookingChargeCredit,
  BookingChargeCreditState,
  BookingChargeState,
  LedgerMonth,
} from '../../../../API/bookingsAndCredits';
import { AuthProvider } from '../../../../Authentication/Authentication';
import { Practice } from '../../../../ServiceContext/user';
import Button from '../../../../shared/Button/Button';
import Modal from '../../../../shared/Modal/Modal';
import EmailInvoiceModalContent from '../../ShareInvoiceFlow/EmailInvoiceModalContent';
import { usePrintedInvoice } from '../../ShareInvoiceFlow/usePrintedInvoice';
import BookingsTable from './BookingsTable';

type Props = {
  authProvider: AuthProvider;
  practice: Practice | undefined;
  periodLedger: LedgerMonth;
  selectedPeriod: string;
};

const ShareInvoiceForPeriodView: React.FC<Props> = ({
  authProvider,
  practice,
  periodLedger,
  selectedPeriod,
}) => {
  const practiceName = practice?.name || '';
  const practiceLocation = (authProvider.managedDentists ?? []).find((dentist) => {
    return dentist.practice && dentist.practice.id === practice?.id;
  })?.locations[0];

  const [isEmailModalOpen, setIsEmailModalOpen] = useState(false);

  const isPendingBooking = (booking: Booking) => {
    return booking.charge && booking.charge.state === BookingChargeState.PENDING;
  };
  const isAppliedCredit = (credit: BookingChargeCredit) => {
    return credit.status === BookingChargeCreditState.APPLIED;
  };

  const bookingsWithPendingCharges: Booking[] = periodLedger.bookings.filter(isPendingBooking);
  const appliedCredits: BookingChargeCredit[] = periodLedger.credits.items.filter(isAppliedCredit);

  const getBalanceDueDate = (selectedPeriod: string) => {
    const [year, month] = selectedPeriod.split('-');
    const selectedDate = new Date(Number(year), Number(month) - 1);
    const seventhDayNextMonth = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      7
    );
    return seventhDayNextMonth.toLocaleString('default', {
      month: 'long',
      day: 'numeric',
      year: 'numeric',
    });
  };

  const { printButton, printComponent } = usePrintedInvoice({
    authProvider,
    balanceDueDate: getBalanceDueDate(selectedPeriod),
    practiceName,
    bookingsWithPendingCharges,
    appliedCredits,
  });

  const standardButtonStyling: string = 'bg-primary text-white hover:opacity-75';
  return (
    <>
      <div className="share-invoice-page flex flex-col gap-4 text-secondary">
        <div className="title text-2xl font-semibold text-left">
          Bookings and Credits / Share Invoice
        </div>
        <div className="subtitle text-xs text-left">
          Below is a preview of your invoice. You can share it by downloading, printing, or emailing
          it.
        </div>
        <div className="content bg-white flex flex-col rounded-md">
          <div className="bookings-charges-title flex flex-row m-4 justify-between">
            <div className={'flex flex-row gap-2 items-center'}>
              <span
                id={'practice-name'}
                className="font-bold text-xl"
              >{`${practiceName} Bookings & Charges`}</span>
            </div>
            <div className="share-buttons flex flex-row gap-3">
              <Button
                onClick={() => setIsEmailModalOpen(true)}
                className={standardButtonStyling}
                workflow={ShareInvoiceWorkflow}
                trackingLabel="Email Invoice Button"
              >
                Email Invoice
              </Button>
              {printButton}
            </div>
          </div>
          <div className="invoice-info flex flex-row text-xs gap-2 ml-6 mb-8">
            <div>
              <span className="font-bold">Due Date: </span>
              <span>{getBalanceDueDate(selectedPeriod)}</span>
            </div>
            <div className="text-base-content text-xs font-normal text-start ml-4 ">
              <div>{practiceLocation?.address1}</div>
              <div>{practiceLocation?.address2}</div>
            </div>
          </div>
          <div className="bookings-table">
            <BookingsTable
              bookingData={bookingsWithPendingCharges}
              appliedCredits={appliedCredits}
              alternateRowStyle={true}
            />
          </div>
        </div>
      </div>
      {printComponent}
      {practice && (
        <Modal
          isOpen={isEmailModalOpen}
          onRequestClose={() => setIsEmailModalOpen(false)}
          shape={'square'}
        >
          <EmailInvoiceModalContent
            authProvider={authProvider}
            practiceId={practice.id}
            selectedPeriod={selectedPeriod}
            onCancelClick={() => setIsEmailModalOpen(false)}
            balanceDue={periodLedger.balance}
          />
        </Modal>
      )}
    </>
  );
};

export default ShareInvoiceForPeriodView;
